import { StyleSheet, Text, View } from 'react-native';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import Titre from '../components/Titre';
import logement_service from '../services/logement.service';
import Button from '../components/Button';
import AjoutTache from '../components/Logement/AjoutTache';
import TachesActuelles from '../components/Logement/TachesActuelles';
import role_service from '../services/role.service';
import { css_black, css_calendar_font_grey, css_darkBlue, css_green, css_white } from '../constants/Colors';
import programmation_service from '../services/programmation.service';
import Calendrier from '../components/Elements/Calendrier';
import ListeWorkflows from '../components/Logement/ListeWorkflows';
import tache_service from '../services/tache.service';
import ExecuteNextStepModale from '../components/Modales/ExecuteNextStepModale';
import DeleteWorkflowModale from '../components/Modales/DeleteWorkflowModale';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AddBadge from '../components/Elements/AddBadge';
import Workflow from '../components/Elements/Workflow';
import { screenWidth } from '../constants/Const';
import NotificationModale from '../components/Modales/NotificationModale';

export default function Logement({seen_by = undefined, selected_id_logement = undefined, selected_id_programmation = undefined, setDisplayLogement = () => true}) {
    const [estNestorOuProprio, setEstNestorOuProprio] = useState(false);
    const [loading, setLoading] = useState(false);
    const [creationLoading, setCreationLoading] = useState(false)
    const [idLogement, setIdLogement] = useState(-1);
    const [idProgrammation, setIdProgrammation] = useState(-1);
    const [informations, setInformations] = useState({
      libelle: "",
      numero_adresse: "",
      type_voie_adresse: "",
      nom_voie_adresse: "",
      ville_adresse: "",
      code_postal_adresse: "",
      id_logement: "",
      photo: ""
    })
    const [selectedValidateur, setSelectedValidateur] = useState(-1);
    const [selectedSection, setSelectedSection] = useState("default");
    const [selectedWorkflow, setSelectedWorkflow] = useState(-1);
    const [selectedDate, setSelectedDate] = useState(undefined);
    const [reservations, setReservations] = useState([]);
    const [programmation, setProgrammation] = useState([]); // ensemble de toutes les tâches
    const [filteredProgrammation, setFilteredProgrammation] = useState([]);  // programmation filtrée
    const [etatWorkflow, setEtatWorkflow] = useState([]); // état actuel du logement
    const [filteredEtatWorkflow, setFilteredEtatWorkflow] = useState([]) // etat du logement filtré
    const [selectedTache, setSelectedTache] = useState(undefined);
    const [selectedAction, setSelectedAction] = useState(undefined);
    const [loadingProgrammation, setLoadingProgrammation] = useState(-1);

    const [beginDate, setBeginDate] = useState(moment.tz('Europe/Paris').startOf('week'));
    const [endDate, setEndDate] = useState(moment.tz('Europe/Paris').add(3,'week').endOf('week'));
    const [monthCurrent, setMonthCurrent] = useState(moment.tz('Europe/Paris').startOf('month'))
    const [joursCoulants, setJoursCoulants] = useState(true);

    const [addFinalObject, setAddFinalObject] = useState({
      id_etape: -1,
      id_workflow: -1,
      id_logement: -1,
      date_echeance: moment.tz("Europe/Paris"),
      libelle_etape: "",
      libelle_workflow: "",
      destinataires: []
    })

    moment().locale('fr')

    const navigate = useNavigate();
    const goBack = () => {
      if (seen_by === undefined) navigate("/accueil");
      else setDisplayLogement(false);
    }

    const readIdLogement = () => {
      let id_logement = undefined;
      if (selected_id_logement !== undefined) {
        id_logement = selected_id_logement;
      } else {
        const queryParameters = new URLSearchParams(window.location.search)
        id_logement = queryParameters.get("which")
      }
      if (id_logement !== undefined && id_logement != null) setIdLogement(id_logement)
      else goBack();
    }
    const readIdProgrammation = () => {
      let id_programmation = undefined;
      if (selected_id_programmation !== undefined) {
        id_programmation = selected_id_programmation;
      } else {
        const queryParameters = new URLSearchParams(window.location.search)
        id_programmation = queryParameters.get("prog");
      }
      if (id_programmation !== undefined && id_programmation != null) setIdProgrammation(id_programmation)
      refresh()
    }
    useEffect(() => {
      AsyncStorage.getItem('workflow').then((r) => {if (r!=null)setSelectedWorkflow(parseInt(r))})
      refresh() 
    },[idLogement])
    

    const executePreviousMonth = () => {
      const tmp = monthCurrent;
      if (joursCoulants) {
        setJoursCoulants(false); 
      } 
      else {
        tmp.subtract(1, "month");
      } 
      setMonthCurrent(tmp.clone());
      setBeginDate(tmp.clone().startOf("week"));
      setEndDate(tmp.clone().endOf("month").endOf("week"));
    }

    const executeNextMonth = () => {
      if (!joursCoulants) {
        const tmp = monthCurrent.clone().add(1, "month");
        if (tmp.month() === moment.tz("Europe/Paris").add(1, 'month').month()) {
          setJoursCoulants(true);
          setBeginDate(moment.tz('Europe/Paris').startOf('week'));
          setEndDate(moment.tz('Europe/Paris').add(3,'W').endOf('week'));
        } else {
          setBeginDate(tmp.clone().startOf("week"));
          setEndDate(tmp.clone().endOf("month").endOf("week"));
          setMonthCurrent(tmp)
        }
      }
    }
    const sendNotification = (id_programmation) => {
      setLoadingProgrammation(id_programmation);
      setSelectedTache(undefined);
      setSelectedAction(undefined);
      programmation_service.sendNotification(id_programmation)
      .then((r) => {
        setLoadingProgrammation(-1)
  
      })
    }  

    useEffect(() => {
      refresh();
    }, [monthCurrent, beginDate, endDate, joursCoulants])


    const refresh = () => {
      if (idLogement !== -1) {
        setLoading(true);
        setReservations([]);
        setEtatWorkflow([]);
        setProgrammation([]);
        setFilteredEtatWorkflow([]);
        setFilteredProgrammation([]);
        logement_service.getInformationsLogement(idLogement, seen_by, beginDate, endDate).then((infos) => {
          setInformations(infos)
          setReservations(infos.reservations)
          setEtatWorkflow(infos.etats_workflows)
          role_service.estNestorOuProprietaire(idLogement, seen_by).then((res) => {
            setEstNestorOuProprio(res)    
            programmation_service.getFuturesProgrammations(idLogement, seen_by, beginDate, endDate).then((fp) => {
              setProgrammation(fp);
              setFilteredEtatWorkflow(!joursCoulants ? fp : selectedDate === undefined && !joursCoulants? 
                                        infos.etats_workflows : filterByDate(fp))
              setFilteredProgrammation(!joursCoulants ? fp : 
                                    selectedDate === undefined && selectedWorkflow === -1 ?
                                    fp.filter(p => !(!moment(p.date_echeance).tz("Europe/Paris").isSame(moment.tz("Europe/Paris"), "day") && p.case === "green") && 
                                                        !(!moment(p.date_echeance).tz("Europe/Paris").isSame(moment.tz("Europe/Paris"), "day") && p.case === "deleted"))               
                                    : filterByWorkflow(filterByDate(fp))) // Par défaut on affiche que les tâches entre aujourd'hui et le futur
              setLoading(false)
            })
          })
        })
      }
    }
    
    useEffect(() => {
      updateAfterFiltering()
    }, [selectedWorkflow, selectedDate, selectedSection])

    const updateAfterFiltering = () => {
      if(selectedDate === undefined) {
        if (selectedWorkflow === -1) {
          setFilteredProgrammation(programmation.filter(p => !(moment(p.date_echeance).tz("Europe/Paris").isBefore(moment.tz("Europe/Paris"), "day") && p.case === "green") && 
          !(!moment(p.date_echeance).tz("Europe/Paris").isSame(moment.tz("Europe/Paris"), "day") && p.case === "deleted")))
        }
        setFilteredEtatWorkflow(etatWorkflow);
      } else {
        setFilteredProgrammation(filterByWorkflow(filterByDate(programmation)))
        setFilteredEtatWorkflow(filterByDate(programmation))
      }
    }
    useEffect(() => {
      if (programmation.filter(p => p.id_programmation == idProgrammation).length !== 0) {
        setSelectedDate(programmation.filter(p => p.id_programmation == idProgrammation)[0].date_echeance);
        setSelectedWorkflow(programmation.filter(p => p.id_programmation == idProgrammation)[0].id_workflow);
      }
    }, [programmation])

    useEffect(() => {
      readIdLogement()
      readIdProgrammation()
    }, [])


    const changeSelectedWorkflow = (id_workflow) =>  {
      AsyncStorage.setItem('workflow' , id_workflow);
      setSelectedWorkflow(id_workflow)
    }

    const executeNextStep = (tache) => {
      setLoadingProgrammation(tache.id_programmation)
      setSelectedTache(undefined)
      setSelectedAction(undefined)
      tache_service.changerEtat({
          id_programmation: tache.id_programmation,
          id_logement: idLogement,
          id_validateur: selectedValidateur
      }).then((r) => {
          refresh()
          setSelectedValidateur(-1)
      })
    }    

    const disableTask = (tache) => {
        setLoadingProgrammation(tache.id_programmation)
        setSelectedTache(undefined)
        setSelectedAction(undefined)
        programmation_service.deleteProgrammation({
            scheduled: tache.scheduled,
            id_programmation: tache.id_programmation,
            id_logement: tache.id_logement,
            id_workflow:tache.id_workflow
        }).then((r) => {
            refresh()
        })
    }
    const filterByWorkflow = (taches) => {
      if (selectedWorkflow === -1)
          return taches
      const result = [];
      taches.forEach((res) => {
          if (res.id_workflow === selectedWorkflow)
              result.push(res)
      })
      return result;
    }
    const filterByDate = (taches) => {
      if (selectedDate === undefined)
          return taches
      const result = [];
      taches.forEach((res) => {
        if (moment(res.date_echeance).tz("Europe/Paris").isSame(moment(selectedDate).tz("Europe/Paris"), "day"))
          result.push(res)
      })
      return result;
    }

    const updateTacheSelection = (fo) => {
      setAddFinalObject({...addFinalObject, destinataires: fo.destinataires, id_etape: fo.id_etape, id_workflow: fo.id_workflow, libelle_etape: fo.libelle_etape, libelle_workflow: fo.libelle_workflow})
    }

    const onNewTask = () => {
      refresh();
      setSelectedSection("default")
    }

    const createNewTache = () => {
      setCreationLoading(true)
      const tmp = addFinalObject;
      tmp.id_logement = idLogement;
      console.log(tmp)
      programmation_service.addTacheInProgrammation(tmp).then((r) => {
        setCreationLoading(false)
        refresh();
        setSelectedSection("default")
      })
    }

    const onDateSelection = (date) => {
      setAddFinalObject({...addFinalObject, date_echeance: moment(date).tz('Europe/Paris')})
    }

    const styles = StyleSheet.create({
      global_ctn: {
        minWidth: Math.min(screenWidth, 500),
        maxWidth: Math.min(screenWidth, 500),
        backgroundColor: css_white,
        minHeight: "100dvh"
      },
      oneButton: {
        margin: "1vw"
      },
      buttons: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: "wrap",
        alignItems: "stretch",
        marginTop: "5vh"
      },
      container_event: {
        marginTop: '1vh',
        marginBottom: '1vh',
        borderColor: css_darkBlue,
        width: "95%",
        borderRadius: "10px"
      },
      scrollView: {
        marginTop: "2vh"
      },
      titre: {
        paddingLeft: "5%",
        paddingTop: "1vw"
      },
      recap: {
        paddingVertical: "2vh",
        marginBottom: "12vh",
        marginTop: "2vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      loading: {
        marginLeft: "5%",
        marginTop: "5%",
        fontWeight:"500"
      }
    });
    


      return (
          <View style={styles.global_ctn}>
              
              <Header onBack={goBack} selection={2} image={{uri: informations.photo}}/>
              <AddBadge setDisplayModale={() => {setSelectedSection(selectedSection === "addTask" ? "default" : "addTask")}}
                        displayModale={selectedSection === "addTask"}/>
              <View style={styles.titre}>
                <Titre  texte={(idLogement === -1) ? "..." : informations.libelle} 
                        size={1.5} 
                        align={"left"} 
                        color={css_black}
                        fontWeight={700}/>
                <Titre  texte={(idLogement === -1) ? "..." : informations.ville_adresse} 
                        size={1} 
                        align={"left"} 
                        icon="location"

                        fontWeight={300}
                        marginLeft={2}
                        color={css_calendar_font_grey}/>
              </View>
              <hr />
              
              
                                
              {selectedSection === "addTask" ? 
                loading ? <Text>Récupération de la liste des workflows en cours ...</Text> :
                <AjoutTache idLogement={idLogement} 
                            onNewTask={onNewTask}
                            onUpdate={updateTacheSelection}
                            goBack={() => {setSelectedSection("default")}}/> : <></>}
                        
              {selectedSection === "default" || selectedSection === "addTask"  ? 
                <View>
                  <Calendrier reservations={reservations}
                              displayByFullMonth={!joursCoulants}
                              executePreviousMonth={()=>{executePreviousMonth()}}
                              executeNextMonth={()=>{executeNextMonth()}}
                              selectedBegin={beginDate}
                              selectedEnd={endDate}
                              currentMonth={monthCurrent}
                              loading={loading}
                              isSelectable={true}
                              selected_date={programmation.filter(p => p.id_programmation == idProgrammation ).length !== 0 ? programmation.filter(p => p.id_programmation == idProgrammation )[0].date_echeance : undefined}
                              addTask={selectedSection === "addTask"}
                              onDateSelection={selectedSection === "addTask" ? onDateSelection 
                                              : setSelectedDate}
                              taches={filterByWorkflow(programmation)}/>
                          {selectedSection === "addTask" ?
                            creationLoading ? 
                            <View style={styles.recap}>
                              <Titre  texte="Programmation de la nouvelle tâche en cours ..."
                                      size={1.3} />

                
                            </View>
                            :
              <View style={styles.recap}>
                <Workflow libelle={addFinalObject.libelle_workflow === "" ? "Workflow à sélectionner." : addFinalObject.libelle_workflow}
                          date_echeance={moment(addFinalObject.date_echeance)}
                          color={moment(addFinalObject.date_echeance).isSameOrBefore(moment(), 'day') ? "red" : moment(addFinalObject.date_echeance).isSame(moment().add(1, 'day').clone(), 'day') ? "orange" : "grey"}
                          no_action={true}
                          destinataires={addFinalObject.destinataires}
                />
                <Button title="Programmer la tâche"
                        color={css_white}
                        background={css_green}
                        customStyle={[{width:"90%"}]}
                        onPress={() => {createNewTache()}}
                      />
              </View>
              : <></>}       
              {loading ?
              <Text style={styles.loading}>Récupération de l'état des workflows en cours ...</Text>:<></>}
              { selectedSection === "addTask" ?
              <></>
              :<><TachesActuelles  onSelection={changeSelectedWorkflow}
                                  taches={filteredEtatWorkflow}
                                  filter={selectedWorkflow} /> 
              <ListeWorkflows idLogement={idLogement}
                              filterWorkflow={selectedWorkflow}
                              programmation={filteredProgrammation}
                              refresh={refresh}
                              loadingProgrammation={loadingProgrammation}
                              setLoadingProgrammation={setLoadingProgrammation}
                              setSelectedAction={setSelectedAction}
                              setSelectedTache={setSelectedTache}
                              onDateSelection={ (date_echeance) => {setAddFinalObject({...addFinalObject, date_echeance: date_echeance})}}
                              nestorOuProprio={estNestorOuProprio}
                                  /></>}
                            
              </View>: <></>}

              
              {selectedTache !== undefined && selectedAction === "next"? 
              <ExecuteNextStepModale  onValidation={() => {executeNextStep(selectedTache)}}
                                      selectedUser={selectedValidateur}
                                      setSelectedUser={setSelectedValidateur}
                                      id_programmation={selectedTache.id_programmation}
                                      disappear={() => setSelectedTache(undefined)}/> :<></>}
              {selectedTache !== undefined && selectedAction === "delete"? 
              <DeleteWorkflowModale  onValidation={() => {disableTask(selectedTache)}}
                                      disappear={() => setSelectedTache(undefined)}/> :<></>}
                                      
              {selectedTache !== undefined && selectedAction === "notify"?
                <NotificationModale   onValidation={() => {sendNotification(selectedTache.id_programmation)}}
                                      id_programmation={selectedTache.id_programmation}
                                      disappear={() => {setSelectedTache(undefined)}} />:<></>}

              
          </View>
          );
      }

