import { StyleSheet, View, Text, Pressable } from 'react-native';
import { css_black, css_red, css_white, css_white_modale } from '../../constants/Colors';
import Button from '../Button';
import { screenWidth } from '../../constants/Const';

export default function DeleteWorkflowModale({disappear = null, onValidation = null}) {


  const styles = StyleSheet.create({ 
    bg: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      width: Math.min(screenWidth, 500),
      height: "100dvh",
      zIndex: 1,
      top: 0,
      left: screenWidth<500? 0 : (screenWidth-500)/2,
      bottom: 0,
      backgroundColor: css_white_modale
  },
  modale: {
      width: Math.min(screenWidth*0.9,500*0.9),
      backgroundColor: css_white,
      borderRadius: "15px",
      borderColor: css_black,
      borderWidth: "3px",
      paddingVertical: "4vh",
      display: "flex",
      justifyContent: "space-around",
      alignContent: "center",
      alignItems: "center",
      cursor: "default"
  },
    buttons: {
        paddingTop: "15%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: "80%"
    }
  });
  

  return (
      <Pressable    style={styles.bg}
                    onPress={disappear}>
        <Pressable  style={styles.modale} 
                    onPress={() => {}}>
            <Text>Êtes-vous sur de vouloir supprimer ?</Text>

            <View style={styles.buttons}>
                <Button onPress={disappear} 
                        title={"Annuler"}
                        color={css_black}
                        borderColor={css_black}
                        size="medium"/>
                <Button onPress={onValidation} 
                        title={"Supprimer"}
                        background={css_red}
                        borderColor={css_red}
                        size="medium"
                        color={css_white}/>
            </View>
        </Pressable>


      </Pressable>
  );
}


