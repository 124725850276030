import { StyleSheet, View, Text, Pressable, ScrollView } from 'react-native';
import { css_black, css_darkBlue, css_green, css_white, css_white_modale } from '../../constants/Colors';
import Button from '../Button';
import { useState } from 'react';
import { screenWidth } from '../../constants/Const';

export default function NotificationModale({disappear = null, onValidation = null, id_programmation = -1}) {
  const styles = StyleSheet.create({ 
    bg: {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: Math.min(screenWidth, 500),
        height: "100dvh",
        zIndex: 1,
        top: 0,
        left: screenWidth<500 ? 0 : (screenWidth-500)/2,
        bottom: 0,
        backgroundColor: css_white_modale
    },
    modale: {
        width: Math.min(screenWidth*0.9, 0.9*500),
        backgroundColor: css_white,
        borderRadius: "15px",
        borderColor: css_black,
        borderWidth: "3px",
        paddingVertical: "4vh",
        display: "flex",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        cursor: "default"
    },
    buttons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: "80%"
    },
    liste: {
      width: "80%",
      marginVertical: "5%",
      maxHeight: "45vh"
    },
    subtitle: {
      fontSize: "14px",
      width: "90%",
      textAlign: "center",
      marginVertical: "2vh"
    }
  });
  

  return (
      <Pressable    style={styles.bg}
                    onPress={disappear}>
        <Pressable  style={styles.modale} 
                    onPress={() => {}}>
            <Text style={styles.subtitle}>Êtes-vous sur de vouloir notifier les personnes associées à la programmation ?</Text>

            <View style={styles.buttons}>
                <Button onPress={disappear} 
                        title={"Annuler"}
                        color={css_black}
                        borderColor={css_black}
                        size="medium"/>
                
                  <Button onPress={onValidation} 
                        title={"Notifier"}
                        background={css_green}
                        borderColor={css_green}
                        size="medium"
                        color={css_white}/>
                
            </View>
        </Pressable>


      </Pressable>
  );
}


