import { StyleSheet, Text, View, Pressable } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { css_bg_grey, css_darkBlue, css_white } from '../constants/Colors';
import {  Ionicons } from '@expo/vector-icons';
import auth_service from '../services/auth.service';
import { useEffect, useState } from 'react';
import role_service from '../services/role.service';
import { screenWidth } from '../constants/Const';

export default function Sidebar({sidebarEnabled=false, setEnableSidebar = null}) {
    const [loading, setLoading] = useState(false);
    const [admin, setIsAdmin] = useState(false);
    const navigate = useNavigate();
    const launchLogOut = () => {
        auth_service.logout();
        navigate("/");
    }
    
    useEffect(() => {
        setLoading(true)
        role_service.estAdministrateur().then((res) => {
            setIsAdmin(res)
            setLoading(false)
        })
    }, []);
    if (!sidebarEnabled) {
        return (<></>);
    }
    else {
        return (<>
            <Pressable style={styles.backLink}  onPress={() => {setEnableSidebar(false)}}><View></View></Pressable>
            <View style={styles.container}>
            {admin? <Pressable  style={[styles.link]}
                                onPress={(e) => {e.preventDefault();navigate('/admin')}}>
                        <Ionicons name="ribbon-outline" size={24} color={css_darkBlue} />
                        <Text style={styles.linkTitle}>Administration</Text>
                    </Pressable>
                    :<></>}
                    
                    <Pressable  style={[styles.link]}
                                onPress={(e) => {e.preventDefault();launchLogOut();}}>
                        <Ionicons name="log-out-outline" size={24} color={css_darkBlue} />
                        <Text style={styles.linkTitle}>Déconnexion</Text>
                    </Pressable>
                    
            </View></>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        position: "fixed",
        right: screenWidth <=500 ? 0 : (screenWidth-500)/2 ,
        backgroundColor: css_bg_grey,
        height: "100dvh",
        width: Math.min(screenWidth*0.60, 500*0.6)
    },
    link: {
        display: "flex",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        marginTop: "6dvh",
        paddingHorizontal: Math.min(screenWidth*0.05, 500*0.05)
    }, 
    linkTitle: {
        marginLeft: Math.min(screenWidth*0.02, 500*0.02),
        fontSize: "1.1rem"
    },
    backLink: {
        position: "fixed",
        width: Math.min(screenWidth, 500),
        height: "100dvh",
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
});
